import { GetterTree }                    from 'vuex';
import { Solution, SolutionForm, State } from '@/views/administration/views/administration-solutions-types/administration-solutions-types.types';
import { RootState }                     from '@/store/store.types';
import { OptionDropdownElement }         from '@/components/application-header/use-application-header';
import { SearchbarSelectItem }             from '@/types';


export const getters: GetterTree<State, RootState> = {
  solutionsTypesTableData(state): Solution[] {
    if (state.solutionsTypes) {
      return state.solutionsTypes.map((solutionType) => {
        return {
          ...solutionType, price: solutionType.price.toString()
        }
      })
    }
    return [];
  },
  selectedSolutionsType(state): SolutionForm {
    if(state.selectedSolutionType){
      return {
        ...state.selectedSolutionType,
        price: state.selectedSolutionType.price.toString()
      }
    }
    return {
      name: '',
      price: '',
      description: '',
    }
  },
  solutionsToReplace(state): SearchbarSelectItem[] {
    return state.solutionTypesToReplace.map(solutionType => ({ label: solutionType.name, uuid: solutionType.uuid }))
  },
};
