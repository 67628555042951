
import { defineComponent, onMounted, Ref, ref } from 'vue';
import { useGlobalTranslations }              from '@/hooks/use-translations';
import cFormErrorMessage         from '@/components/common/form/form-error-message/form-error-message.component.vue';
import CFormErrorIcon            from '@/components/common/form/form-error-icon/form-error-icon.component.vue';

export default defineComponent({
  name: 'CFormTextarea',
  components: { CFormErrorIcon, cFormErrorMessage },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: [String, Number],
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    focus: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default:320
    },
  },
  emits: ['update:modelValue'],
  setup(props) {
    const textAreaHTMLElement: Ref<HTMLTextAreaElement | null> = ref(null)

    onMounted(() => {
      if(props.focus)
        textAreaHTMLElement.value && textAreaHTMLElement.value.focus()
    })


    return {
      useGlobalTranslations,
      textAreaHTMLElement
    };
  },
});
