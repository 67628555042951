import { MutationTree }         from 'vuex';
import { Solution, State }                    from '@/views/administration/views/administration-solutions-types/administration-solutions-types.types';
import { AdministrationParams, SidePanelState } from '@/types';

export const mutations: MutationTree<State> = {
  setAllSolutionsTypes(state, payload: Solution[]): void {
    state.solutionsTypes = payload;
  },

  setTableParams(state, params: AdministrationParams): void {
    state.tableParams = params;
  },

  setCurrentItem(state, solution: Solution): void {
    state.selectedSolutionType = solution;
  },
  setSidePanelState(state, payload: SidePanelState) {
    state.sidePanelState = payload;
  },
  setSolutionTypesToReplace(state, payload: Solution[]) {
    state.solutionTypesToReplace = payload
  }
};

