

import { computed, ComputedRef, defineComponent, reactive } from 'vue';
import useVuelidate, { ValidationArgs }                     from '@vuelidate/core';
import { required }                                         from '@vuelidate/validators';
import { useTranslations }                                  from '@/hooks/use-translations';
import CFormInput                                           from '@/components/common/form/form-input/form-input.component.vue';
import CFormTextarea                                        from '@/components/common/form/form-textarea/form-textarea.component.vue';
import { Solution, SolutionForm, SolutionsTypesFormRules }  from '@/views/administration/views/administration-solutions-types/administration-solutions-types.types';
import { FormMode }                                         from '@/views/administration/administration.types';
import CAdministrationFormButtons                           from '@/views/administration/components/administration-form-buttons/administration-form-buttons.component.vue';
import { useGlobals }                                       from '@/hooks/use-globals';
import { Notification }                                     from '@/components/notification/notification';
import i18n                                                 from '@/i18n';
import { GLOBAL }                                           from '@/helpers/variables';


export default defineComponent({
  name: 'CSolutionsTypesForm',
  components: { CAdministrationFormButtons, CFormTextarea, CFormInput },
  props: {
    formMode: {
      type: String,
      default: FormMode.ADD,
    },
  },
  emits: ['close-panel'],
  setup(props, { emit }) {
    const { store } = useGlobals();
    const selectedSolutionsType: ComputedRef<SolutionForm> = computed(() => store.getters['views/solutionsTypes/selectedSolutionsType']);
    const rules: SolutionsTypesFormRules = {
      name: { required },
      price: { required },
    };

    const formFields: SolutionForm = reactive(selectedSolutionsType.value);

    const v$ = useVuelidate((rules as unknown) as ValidationArgs, formFields as any);

    const submitSolutionsForm = async (closePanel: boolean): Promise<void> => {
      v$.value.$touch();
      if (!v$.value.$error) {
        switch (props.formMode) {
          case FormMode.ADD:
            await store.dispatch('views/solutionsTypes/createNewSolution', formFields);
            await store.dispatch('views/solutionsTypes/getAllSolutionsTypes');
            break;
          case FormMode.EDIT:
            await store.dispatch('views/solutionsTypes/editSolutionEntity', formFields as Solution);
            await store.dispatch('views/solutionsTypes/getAllSolutionsTypes');
            break;
          default:
            break;
        }
        v$.value.$reset();
        formFields.name = '';
        formFields.price = '';
        formFields.description = '';
        if (closePanel) {
          emit('close-panel');
        }
      } else {
        Notification.warning(
          i18n.global.t(`${GLOBAL}.warning.warning`),
          i18n.global.t(`${GLOBAL}.warning.validation`),
        );
      }
    };

    const deleteSolution = async (): Promise<void> => {
      await store.dispatch('views/solutionsTypes/deleteSolutionEntity', formFields.uuid);
      await store.dispatch('views/solutionsTypes/getAllSolutionsTypes');
      emit('close-panel');
    };


    return {
      submitSolutionsForm,
      useTranslations,
      formFields,
      v$,
      FormMode,
      deleteSolution,
    };
  },
});
