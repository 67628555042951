
import { computed, ComputedRef, defineComponent, ref, Ref } from 'vue';
import CFormSolutions
  from '@/views/administration/views/administration-solutions-types/components/solutions-types-form/solutions-types-form.vue';
import CAdministrationTemplate
  from '@/views/administration/components/administration-template/administration-template.component.vue';
import CSidePanel                                                                 from '@/components/common/side-panel/side-panel.component.vue';
import { useTranslations, useGlobalTranslations }                                 from '@/hooks/use-translations';
import { useGlobals }                                                             from '@/hooks/use-globals';
import { TableColumn, TableRow }                                                  from '@/components/common/table/table.types';
import { AdministrationParams, FilterGroup, SearchbarSelectItem, SidePanelState } from '@/types';
import { AdministrationPagination, FormMode }                                     from '@/views/administration/administration.types';
import {
  Solution,
  SolutionTypePayload
}                                                                                 from '@/views/administration/views/administration-solutions-types/administration-solutions-types.types';
import CDeleteConfirmation                                                        from '@/views/administration/components/delete-confirmation/delete-confirmation.component.vue';


export default defineComponent({
  name: 'VAdministrationSolutionsTypes',
  components: { CDeleteConfirmation, CSidePanel, CAdministrationTemplate, CFormSolutions },
  setup() {
    const { store, i18n } = useGlobals();
    const columns: TableColumn[] = store.state.views.solutionsTypes.columns;
    const rows: ComputedRef<TableRow[]> = computed(() => store.getters['views/solutionsTypes/solutionsTypesTableData']);
    const params: ComputedRef<Partial<AdministrationParams>> = computed(() => store.state.views.solutionsTypes.tableParams);
    const selectedSolution: ComputedRef<Solution> = computed(() => store.state.views.solutionsTypes.selectedSolutionType)
    const isSidePanelOpen: Ref<boolean> = ref(false);
    const sidePanelTitle: Ref<string> = ref('');
    const formMode: Ref<FormMode> = ref(FormMode.ADD);
    const sidePanelState: ComputedRef<SidePanelState> = computed(() => store.state.views.solutionsTypes.sidePanelState);
    const solutionsToReplace: ComputedRef<SearchbarSelectItem[]> = computed(() => store.getters['views/solutionsTypes/solutionsToReplace'])

    store.commit('views/solutionsTypes/setTableParams', []);
    store.dispatch('views/solutionsTypes/getAllSolutionsTypes');

    const closeSidePanel = (): void => {
      isSidePanelOpen.value = false;
      store.commit('views/solutionsTypes/setSidePanelState', SidePanelState.EDIT_ENTITY);
      store.commit('views/solutionsTypes/setCurrentItem', null);
    };

    const openAddNewSolutionPanel = (): void => {
      formMode.value = FormMode.ADD;
      sidePanelTitle.value = i18n.t('solutionsTypes.sidePanel.addNewSolution');
      isSidePanelOpen.value = true;
    };

    const openEditSolutionPanel = async (uuid: Solution): Promise<void> => {
      closeSidePanel();
      await store.dispatch('views/solutionsTypes/getSolutionEntity', uuid);
      formMode.value = FormMode.EDIT;
      sidePanelTitle.value = i18n.t('solutionsTypes.sidePanel.editSolution');
      isSidePanelOpen.value = true;
    };

    const updateParams = async (updatedParams: Partial<AdministrationParams>): Promise<void> => {
      store.commit('views/solutionsTypes/setTableParams', updatedParams);
      await store.dispatch('views/solutionsTypes/getAllSolutionsTypes');
    };

    const goBackToEditForm = (): void => {
      store.commit('views/solutionsTypes/setSidePanelState', SidePanelState.EDIT_ENTITY)
    }

    const replaceSolutionToDelete = async (replaceToSolutionUuid: string): Promise<void> => {
      await store.dispatch('views/solutionsTypes/replaceSolution', { solutionUuid: selectedSolution.value.uuid, replaceToSolutionUuid })
      store.dispatch('views/solutionsTypes/getAllSolutionsTypes')
      closeSidePanel()

    }


    return {
      useGlobalTranslations,
      openAddNewSolutionPanel,
      openEditSolutionPanel,
      useTranslations,
      closeSidePanel,
      updateParams,
      formMode,
      columns,
      rows,
      params,
      isSidePanelOpen,
      sidePanelTitle,
      AdministrationPagination,
      sidePanelState,
      solutionsToReplace,
      SidePanelState,
      goBackToEditForm,
      replaceSolutionToDelete
    };
  },
});
