import { columnTypes }                 from '@/components/common/table/table.types';
import { State }                       from '@/views/administration/views/administration-solutions-types/administration-solutions-types.types';
import { FormMode }                    from '@/views/administration/administration.types';
import { FilterTypes, SidePanelState } from '@/types';

export const state: State = {
  solutionTypesToReplace: [],
  sidePanelState: SidePanelState.EDIT_ENTITY,
  solutionsTypes: null,
  selectedSolutionType: {
    description: '',
    price: 0,
    name: '',
    uuid: ''
  },

  tableParams: {
    searchQuery: '',
    'sortBy[0].direction': 'ASC',
    'sortBy[0].field': 'name'
  },

  columns: [
    { name: 'name', type: columnTypes.TEXT, filter: FilterTypes.SORT },
    { name: 'price', type: columnTypes.TEXT },
    { name: 'description', type: columnTypes.TEXT },
  ],
  formMode: FormMode.ADD,
};
