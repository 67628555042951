import { ActionContext, ActionTree } from 'vuex';
import {
  Solution,
  SolutionTypePayload,
  State,
} from '@/views/administration/views/administration-solutions-types/administration-solutions-types.types';
import { RootState } from '@/store/store.types';
import { GLOBAL }                                                 from '@/helpers/variables';
import { AdministrationParams, DataWithPagination, SidePanelState } from '@/types';
import {
  createEntity,
  deleteSingleEntity, getEntitiesToReplace,
  getSingleEntity,
  getTableData, replaceEntity,
  updateSingleEntity,
}                                                                 from '@/views/administration/administration.service';
import { Notification } from '@/components/notification/notification';
import i18n from '@/i18n';
import {
  AdministrationEndpoints,
  AdministrationPagination, DeleteEntityResponse,
} from '@/views/administration/administration.types';

export const actions: ActionTree<State, RootState> = {
  async getAllSolutionsTypes({
    commit,
    rootState,
    state,
  }: ActionContext<State, RootState>): Promise<void> {
    try {
      const { pageSize, currentPage } = rootState.pagination[
        AdministrationPagination.SOLUTIONS_TYPES
      ];
      const params: Partial<AdministrationParams> = state.tableParams;
      const allParams: Partial<AdministrationParams> = {
        ...params,
        page: currentPage,
        size: pageSize,
      };

      const {
        data,
      }: { data: DataWithPagination<Solution> } = await getTableData(
        AdministrationEndpoints.SOLUTIONS_TYPES,
        allParams
      );
      commit('setAllSolutionsTypes', data.content);
      commit(
        'updatePaginationElements',
        {
          totalElements: data.totalElements,
          name: AdministrationPagination.SOLUTIONS_TYPES,
        },
        { root: true }
      );
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async getSolutionEntity(
    { commit }: ActionContext<State, RootState>,
    uuid: string
  ): Promise<void> {
    try {
      const { data }: { data: Solution } = await getSingleEntity(
        AdministrationEndpoints.SOLUTIONS_TYPES,
        uuid
      );
      commit('setCurrentItem', data);
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async deleteSolutionEntity(
    { dispatch }: ActionContext<State, RootState>,
    uuid: string
  ): Promise<void> {
    try {
      await deleteSingleEntity(AdministrationEndpoints.SOLUTIONS_TYPES, uuid);
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('solutionsTypes.notifications.delete')
      );
    } catch (e) {
      e.data.errorCode === DeleteEntityResponse.DELETE_RESTRICT
        ? dispatch('getSolutionsToReplace', uuid)
        : Notification.error(
          i18n.global.t(`${GLOBAL}.errors.error`),
          i18n.global.t(`${GLOBAL}.errors.${e.data}`)
        );
      throw e.data;
    }
  },
  async editSolutionEntity(
    context: ActionContext<State, RootState>,
    payload: Solution
  ): Promise<void> {
    try {
      await updateSingleEntity(
        AdministrationEndpoints.SOLUTIONS_TYPES,
        payload.uuid,
        payload
      );
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('solutionsTypes.notifications.success')
      );
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async createNewSolution(
    context: ActionContext<State, RootState>,
    payload: SolutionTypePayload
  ): Promise<void> {
    try {
      await createEntity(AdministrationEndpoints.SOLUTIONS_TYPES, payload);
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('solutionsTypes.notifications.success')
      );
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },
  async getSolutionsToReplace({ commit }, uuid: string) {
    try {
      const { data } = await getEntitiesToReplace(AdministrationEndpoints.SOLUTIONS_TYPES, uuid)
      commit('setSolutionTypesToReplace', data)
      commit('setSidePanelState', SidePanelState.DELETE_ENTITY)
    }
    catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },
  async replaceSolution(context, { solutionUuid, replaceToSolutionUuid }: {solutionUuid: string, replaceToSolutionUuid: string}) {
    try {
      await replaceEntity(AdministrationEndpoints.SOLUTIONS_TYPES, solutionUuid, replaceToSolutionUuid)
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('solutionsTypes.notifications.replace')
      );
    }
    catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  }
};
